<template>
  <div class="train-container container">
    <div class="container-fluid">
      <NavTitle
        title="首页"
        conton="培训动态"
        :isBack="false"
        @navBackIncident="navBackIncident"
      >
      </NavTitle>
      <h1>{{ informationObj.subject }}</h1>
      <div class="train-box flex conet j-w">
        <div class="train-box-sum flex conet">
          <span
            >发稿时间：{{
              informationObj.createDate
                | timeFormatter("{year}年{month}月{day}日")
            }}
          </span>
          <span class="text">浏览量：{{ informationObj.scanNum || 0 }}</span>
        </div>
        <div class="flex conet">
          <div
            :class="[
              'collection',
              'lef',
              'flex',
              'conet',
              youIsCollect ? 'active' : '',
            ]"
            @click.stop="attentionIncident($event, 'collection')"
          >
            {{ sumStore || 0 }}
          </div>
          <div
            :class="['zan', 'lef', 'flex', 'conet', youIsLike ? 'active' : '']"
            @click="attentionIncident($event, 'zan')"
          >
            {{ sumLike || 0 }}
          </div>
        </div>
      </div>
      <div class="content" v-html="informationObj.content"></div>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils';
import NavTitle from '@/components/nav-title/index';
import { mapGetters } from 'vuex';
import { pupopStatus } from '@/config/pupupUtils';
import {
  informationDetailInformationId,
  informationLikeInformationId,
  informationStoreInformationId
} from '@/api/api';
import { timeFormatter, routerReplace } from '@/utils/verify';
export default {
  name: 'Train',
  filters: { timeFormatter },
  components: {
    NavTitle
  },
  data() {
    return {
      youIsCollect: false,
      youIsLike: false,
      informationObj: {
        subject: '',
        createDate: '',
        scanNum: '',
        content: ''
      },
      sumStore: '',
      sumLike: ''
    };
  },
  computed: {
    ...mapGetters(['consumType'])
  },
  created() {
    this.$nextTick(() => {
      this.init();
    })
  },
  methods: {
    init() {
      const { path } = this.$route.query;
      if (path == '/agreement' && !this.consumType) {
        this.$store.commit('isChecked', false);
        this.$qrCode().then((res) => {
          this.getInformationDetailInformationId();
        });
      }
      this.getInformationDetailInformationId();
    },
    getInformationDetailInformationId() {
      const { id } = this.$route.query;
      const jsonData = { informationId: id };
      informationDetailInformationId(jsonData).then((res) => {
        if (res.code == 200 && res.data != null) {
          this.informationObj = res.data;
          this.commitInformation(res)
        }
      });
    },
    commitInformation(res) {
      this.youIsCollect = res.data.youIsCollect;
      this.youIsLike = res.data.youIsLike;
      this.sumStore = res.data.collectAndLikeNum.sumStore;
      this.sumLike = res.data.collectAndLikeNum.sumLike;
    },
    attentionIncident(event, method) {
      const { id } = this.$route.query;
      const jsonData = {
        informationId: id
      };
      switch (method) {
        case 'collection':
          if (this.consumType) {
            informationStoreInformationId(jsonData).then((res) => {
              if (res.code == 200) {
                // this.init();
                if (!this.youIsCollect) {
                  Utils.msgSuccess('收藏成功');
                } else {
                  Utils.msgSuccess('取消收藏成功');
                }
                this.commitInformation(res)
              }
            });
          } else {
            this.register();
          }
          break;
        case 'zan':
          if (this.consumType) {
            informationLikeInformationId(jsonData).then((res) => {
              if (res.code == 200) {
                // this.init();
                if (!this.youIsLike) {
                  Utils.msgSuccess('点赞成功');
                } else {
                  Utils.msgSuccess('取消点赞成功');
                }
                this.commitInformation(res)
              }
            });
          } else {
            this.register();
          }
          break;
        default:
          break;
      }
    },
    register() {
      const obj = {
        title: pupopStatus(1)[1],
        leftBtn: pupopStatus(2)[0],
        rightBtn: pupopStatus(3)[0]
      };
      this.$trainPopup(obj).then((res) => {
        if (res.code == 200) {
          this.$qrCode().then((res) => {
            this.getInformationDetailInformationId();
          });
        }
      });
    },
    navBackIncident() {
      routerReplace(this, '/');
    }
  }
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common";
.train {
  &-container {
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    box-sizing: border-box;
    h1 {
      .fontSize(@TEXT-SIZE-24);
      text-align: center;
      font-weight: bold;
      line-height: 31px;
    }
  }

  &-box {
    padding: 18px 0 40px 0;
    width: 800px;
    margin: 0 auto;
    &-sum {
      .fontSize(@TEXT-SIZE-14);
      color: @TEXT-COLOR-888888;
      flex: 1;
    }
    .text {
      margin-left: 20px;
    }
    .lef {
      box-sizing: border-box;
      height: 18px;
      padding-left: 28px;
      .fontSize(@TEXT-SIZE-14);
      color: @TEXT-COLOR-888888;
      cursor: pointer;
    }
    .collection {
      background: url("~@/assets/images/curriculum/collection.png") no-repeat
        0px 0px;
      margin-right: 40px;
      &.active {
        background: url("~@/assets/images/curriculum/collection-1.png")
          no-repeat 0px 0px;
      }
    }
    .zan {
      background: url("~@/assets/images/curriculum/zan.png") no-repeat 0px 0px;
      &.active {
        background: url("~@/assets/images/curriculum/zan-1.png") no-repeat 0px
          0px;
      }
    }
  }
}
.content {
  width: 800px;
  margin: 0 auto;
  text-align: justify;
  text-justify: inter-ideograph;
  text-indent: 2em;
  margin-bottom: 20px;
  .fontSize(@TEXT-SIZE-16);
  color: @TEXT-COLOR-888888;
  line-height: 30px;
  /deep/p > span {
    display: flex !important;
    flex: 1 !important;
    width: 100% !important;
    margin-top: 20px !important;
    padding-left: 3px !important;
  }
}
</style>